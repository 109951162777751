import { formatPhoneNumber } from './formatPhoneNumber';
import isInRole from './isInRole';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

export const getMatterSource = (originator, source) => {
    const isClient = isInRole(originator?.roles || [], 'client');
    if (isClient) {
        return 'Client Self Book';
    }

    const isLawyer = isInRole(originator?.roles || [], 'lawyer');
    const isAgent = isInRole(originator?.roles || [], 'agent');
    if (isLawyer || isAgent) {
        return `${originator?.firstName || ''} ${
            !!originator?.lastName ? `${originator.lastName.charAt(0)}.` : ''
        }`;
    }

    return source?.displayName || source?.name || '';
};

export const displayPaymentMethod = (paymentMethod) => {
    if (!paymentMethod) {
        return '-';
    }

    return paymentMethod === 'CreditCard' ? 'Credit Card' : paymentMethod;
};

export const validateAppointmentDate = (lawyers, selectedDate) => {
    let isValid = false;

    for (let i = 0; i < lawyers.length; i++) {
        let lawyer = lawyers[i];
        if (lawyer.success) {
            let lawyerCalendar = lawyer.calendar[0];
            if (!!lawyerCalendar) {
                for (let k = 0; k < lawyerCalendar.schedule.length; k++) {
                    let selectedTime = moment(selectedDate).format('H:mm');
                    if (
                        moment(selectedTime, 'H:mm').isSameOrAfter(
                            moment(
                                lawyerCalendar.schedule[k].starthours,
                                'H:mm',
                            ),
                        ) &&
                        moment(selectedTime, 'H:mm')
                            .add('30', 'minutes')
                            .isSameOrBefore(
                                moment(
                                    lawyerCalendar.schedule[k].endhours,
                                    'H:mm',
                                ),
                            )
                    ) {
                        isValid = true;
                        break;
                    }
                }
            }
        }
    }

    return isValid;
};

export const getAreaTitle = (referral) => {
    let title =
        referral?.lsrPracticeArea?.sourceLrsArea?.name ||
        referral?.lsrPracticeArea?.name ||
        '';
    if (!!referral?.lrsPracticeAreas && referral.lrsPracticeAreas.length > 0) {
        title += `, ${referral.lrsPracticeAreas
            .map((item) => item.name)
            .join(', ')}`;
    }

    if (!!referral?.legalIssues && referral.legalIssues.length > 0) {
        title += `, ${referral.legalIssues
            .map((item) => item.name)
            .join(', ')}`;
    }
    return title;
};

export const getDragged = (newList, oldList) => {
    const result = newList.filter(function (o1) {
        // filter out (!) items in result2
        return !oldList.some(function (o2) {
            return o1.id === o2.id; // assumes unique id
        });
    });

    return result[0];
};

export const getEcbaReferralStatus = (referral, asLink = false) => {
    if (referral.caseStatus === 'Uncompleted') {
        if (asLink) {
            return (
                <Link
                    to={`/agent_us_referrals?searchCriteria=${
                        referral.matterId
                    }${referral?.isClosed ? '&isClosed=true' : ''}`}
                >
                    {referral?.waitingStatus || ''}
                </Link>
            );
        }

        return referral?.waitingStatus || '';
    }

    if (referral.caseStatus === 'Pending') {
        if (asLink) {
            return (
                <Link
                    to={`/agent_us_referrals?searchCriteria=${
                        referral.matterId
                    }${referral?.isClosed ? '&isClosed=true' : ''}`}
                >
                    New
                </Link>
            );
        }
        return 'New';
    }

    if (referral.caseStatus === 'Cancelled') {
        if (asLink) {
            return (
                <Link
                    to={`/agent_us_referrals?searchCriteria=${
                        referral.matterId
                    }${referral?.isClosed ? '&isClosed=true' : ''}`}
                >
                    {`${referral.caseStatus}${
                        !!referral.cancelledData
                            ? ` - ${referral?.cancelledData?.reason || ''}`
                            : ''
                    }`}
                </Link>
            );
        }

        return `${referral.caseStatus}${
            !!referral.cancelledData
                ? ` - ${referral?.cancelledData?.reason || ''}`
                : ''
        }`;
    }

    if (referral.caseStatus === 'Declined') {
        if (asLink) {
            return (
                <Link
                    to={`/agent_us_referrals?searchCriteria=${
                        referral.matterId
                    }${referral?.isClosed ? '&isClosed=true' : ''}`}
                >
                    {`${referral.caseStatus}${
                        (referral?.referralDeclines || []).length > 0
                            ? ` - ${
                                  referral?.referralDeclines[0]?.declineReason
                                      ?.name || ''
                              }`
                            : ''
                    }`}
                </Link>
            );
        }

        return `${referral.caseStatus}${
            referral.referralDeclines.length > 0
                ? ` - ${
                      referral?.referralDeclines[0]?.declineReason?.name || ''
                  }`
                : ''
        }`;
    }

    if (referral.caseStatus === 'Complete') {
        if (asLink) {
            if (referral.isMatter) {
                return (
                    <Link
                        to={`/agent_us_matters?searchCriteria=${referral.matterId}`}
                    >
                        {referral.leadStatus === 'Hired'
                            ? 'Open'
                            : referral.leadStatus === 'Closed'
                            ? referral.leadStatusDescription
                            : referral.leadStatus}
                    </Link>
                );
            } else {
                return (
                    <Link
                        to={`/agent_us_leads?${
                            referral.leadStatus === 'Closed'
                                ? 'activeTab=2&'
                                : ''
                        }searchCriteria=${referral.matterId}`}
                    >
                        {referral.leadStatus === 'Hired'
                            ? 'Open'
                            : referral.leadStatus === 'Closed'
                            ? referral.leadStatusDescription
                            : referral.leadStatus}
                    </Link>
                );
            }
        }

        return referral.leadStatus === 'Hired'
            ? 'Open'
            : referral.leadStatus === 'Closed'
            ? referral.leadStatusDescription
            : referral.leadStatus;
    }
};

export const getEcbaReferralStage = (referral) => {
    if (referral.caseStatus === 'Uncompleted') {
        return 'Pending Referral';
    }

    if (
        referral.caseStatus === 'Pending' ||
        referral.caseStatus === 'Declined' ||
        referral.caseStatus === 'Cancelled'
    ) {
        return 'Referral';
    }

    if (
        referral.isMatter &&
        ['Hired', 'Closed', 'Hold'].includes(referral.leadStatus)
    ) {
        return 'Matter';
    }

    return 'Lead';
};

export const getLrsUserPrimaryPhone = (lrsUser) => {
    if (!lrsUser.phoneNumber) {
        return '';
    }

    let phoneNumber = formatPhoneNumber(lrsUser.phoneNumber);
    if (lrsUser.isPrimaryPhoneMobile) {
        phoneNumber += ' - Mobile';
    }
    return phoneNumber;
};

export const getTransactionReferredBy = (user) => {
    const isClient = isInRole(user?.roles || [], 'client');
    if (isClient) {
        return 'Selfbook';
    }

    return `${user?.firstName || ''} ${user?.lastName || ''}`;
};

export const cleanObject = (obj) => {
    for (var propName in obj) {
        if (typeof obj[propName] === 'object') {
            cleanObject(obj[propName]);
        }
        if (!obj[propName]) {
            delete obj[propName];
        }
    }
    return obj;
};

export const isLFPSource = (sources) => {
    return (
        sources.findIndex((item) => {
            if (!!item.config) {
                let sourceConfig = JSON.parse(item.config);
                return sourceConfig.isLFP;
            }
            return false;
        }) > -1
    );
};

export const practiceAreasCrop = (referral, charsCount) => {
    let prArea =
        referral?.lsrPracticeArea?.sourceLrsArea?.name ||
        referral?.lsrPracticeArea?.name ||
        '';

    if (referral.lrsPracticeAreas.length > 0) {
        prArea += ` > ${referral.lrsPracticeAreas
            .map((item) => item.name)
            .join(', ')}`;
    }

    if (referral.practiceArea) {
        prArea +=
            referral?.practiceArea?.sourceArea?.name ||
            referral?.practiceArea?.name ||
            '';
    }
    if (referral.legalIssues.length > 0) {
        prArea += `${
            !!referral.practiceArea ? ' > ' : ''
        } ${referral.legalIssues
            .map((item) => item?.sourceArea?.name || item?.name)
            .join(', ')}`;
    }

    if (charsCount && prArea.length > charsCount) {
        prArea = prArea.substring(0, charsCount) + '...';
    }

    return prArea;
};

export const isInSource = (userSources, sourceId) => {
    // eslint-disable-next-line
    return userSources.findIndex((item) => item.id == sourceId) > -1;
};

export const referredBy = (referral, isCreatedData = false) => {
    const isLawyer = isInRole(referral?.originator?.roles || [], 'lawyer');
    const isAgent = isInRole(referral?.originator?.roles || [], 'agent');
    const isElcAgent = isInRole(referral?.originator?.roles || [], 'LRS Agent');
    if (isLawyer || isAgent || isElcAgent) {
        return `${referral?.originator?.firstName || ''} ${
            !!referral?.originator?.lastName
                ? `${referral?.originator.lastName.charAt(0)}.`
                : ''
        }`;
    }

    if (!!referral.waitingStatus) {
        if (!!isCreatedData) {
            return 'Web Form';
        }

        return '';
    }

    return 'Client Self-book';
};

export const referralCreatedBy = (referral) => {
    if (referral?.isReferralRequest) {
        return 'Referral Request';
    }

    if (referral.isAnonymous) {
        if (!!referral.waitingStatus) {
            return 'Web Form';
        }

        return 'Client Self-book';
    }

    return `${referral?.originator?.firstName || ''} ${
        !!referral?.originator?.lastName
            ? `${referral?.originator.lastName.charAt(0)}.`
            : ''
    }`;
};

export const referralReferredBy = (referral) => {
    if (referral.isAnonymous && !referral.waitingStatus) {
        return 'Client Self-book';
    }

    return `${referral?.originator?.firstName || ''} ${
        !!referral?.originator?.lastName
            ? `${referral?.originator.lastName.charAt(0)}.`
            : ''
    }`;
};

export const formatAmount = (amount) => {
    let retValue = amount.toFixed(2);

    if (retValue.endsWith('.00')) {
        // Remove decimal places
        retValue = Math.floor(amount);
    }

    return retValue;
};

export const calculateForwardingFee = (amount, payload) => {
    let forwardingFee = 0;

    if (
        payload.isComulative &&
        (amount > payload.freeAmount ||
            payload.totalAmount > payload.freeAmount)
    ) {
        payload.freeAmount = 0;
    }
    payload.fees.sort((a, b) => a.amount - b.amount);
    if (payload.isComulative) {
        payload.fees = payload.fees.filter((fee) => {
            return fee.amount > payload.totalAmount;
        });

        if (payload.fees.length) {
            payload.fees[0].amount =
                payload.fees[0].amount - payload.totalAmount;
        }
    }

    let remaining = amount - payload.freeAmount;

    // Apply fee for amounts up to max defined in the tiers
    for (const fee of payload.fees) {
        const applicableAmount = Math.min(
            remaining,
            fee.amount - payload.freeAmount,
        );
        forwardingFee += (applicableAmount * fee.percent) / 100;
        remaining -= applicableAmount;

        if (remaining <= 0) {
            break; // No need to iterate further if all fees are covered
        }
    }

    // Apply basePercent for the remaining amount over $15,000
    if (remaining > 0) {
        if (payload.isFlatFee) {
            forwardingFee = payload.flatFeeAmount;
        } else {
            forwardingFee += remaining * (payload.basePercent / 100);
        }
    }
    //}

    if (forwardingFee < 0) {
        forwardingFee = 0;
    }

    let qaseFeePercentAmount = Number(
        ((forwardingFee * payload.qaseFeePercent) / 100).toFixed(2),
    );

    let totalQaseFee = Number(
        (
            (forwardingFee * payload.qaseFeePercent) / 100 +
            payload.qaseFeePerTransactionAmount
        ).toFixed(2),
    );

    return {
        forwardingFee: Number(forwardingFee.toFixed(2)),
        qaseFeePercentAmount,
        totalQaseFee,
        qaseFeePerTransactionAmount: payload.qaseFeePerTransactionAmount,
        amount,
        totalAmount: payload.totalAmount,
    };
};

export const sleep = (delay) =>
    new Promise((resolve) => setTimeout(resolve, delay));

export const transformTree = (
    tree,
    propMapping,
    sortFn = null,
    disableArray = [],
) => {
    // Transform and sort the tree
    const transformedTree = tree.map((node) => {
        const transformedNode = {};

        // Map each property based on the propMapping
        for (const [newProp, oldProp] of Object.entries(propMapping)) {
            if (oldProp === 'children') {
                // Recursively transform and sort children
                transformedNode[newProp] = transformTree(
                    node[oldProp] || [],
                    propMapping,
                    sortFn,
                    disableArray,
                );
            } else {
                // Directly copy the property
                transformedNode[newProp] = node[oldProp];
            }
        }

        // Add disabled property if the node's value is in disableArray
        if (disableArray.includes(node[propMapping.value])) {
            transformedNode.disabled = true;
        }

        return transformedNode;
    });

    // Sort the transformed nodes if a sorting function is provided
    return sortFn ? transformedTree.sort(sortFn) : transformedTree;
};

export const sortBasePracticeAreaTreeDataFn = (a, b) => {
    const hasChildrenA = a.children && a.children.length > 0;
    const hasChildrenB = b.children && b.children.length > 0;

    if (hasChildrenA && !hasChildrenB) {
        return -1; // Panels with sub-panels come first
    }
    if (!hasChildrenA && hasChildrenB) {
        return 1; // Panels without sub-panels come later
    }

    // Sort alphabetically by name (or the mapped property)
    return a.title.localeCompare(b.title);
};

export const getFieldErrorNames = (formikErrors) => {
    const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(nextKey);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};

export const getFieldErrorMessages = (formikErrors) => {
    console.log(formikErrors);
    const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
        Object.keys(obj).forEach((key) => {
            const value = obj[key];
            if (!value) return;

            const nextKey = prefix ? `${prefix}.${key}` : key;
            if (typeof value === 'object') {
                transformObjectToDotNotation(value, nextKey, result);
            } else {
                result.push(value);
            }
        });

        return result;
    };

    return transformObjectToDotNotation(formikErrors);
};
